
import {computed, defineComponent, onMounted, ref} from "vue";
import {ErrorMessage, Field, Form} from "vee-validate";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';
import {Turkish} from "flatpickr/dist/l10n/tr"

import {dateConvert} from "@/core/filters/datatime";

import weekDaysList from "@/core/data/weekdays";
import roomMinAccType from "@/core/data/roomminacctype";
import roomProfitType from "@/core/data/roomprofittype";
import roomCalcPriceType from "@/core/data/roomcalcpricetype";


import curList from "@/core/data/currency";

import roomPriceType from "@/core/data/roompricetype";
import roomPricePlatform from "@/core/data/roompriceplatform";

import MasterConfig from "@/core/config/MasterConfig";
import curExchangeType from "@/core/data/curexchangetype";
import {commonf} from "@/core/filters/common";

interface newPriceData {
  priceRoom: string;
  priceConcept: string;
  pricePlatform: string;
  priceType: string;
  dateReservationStart: string;
  dateReservationFinish: string;
  dateValidStart: string;
  dateValidFinish: string;
  priceMarket: string;

  chkValidDay: Array<any>;
  chkCheckInDay: Array<any>;

  minAccType: string;
  minAccStatus: string;
  minAccDay: string;
  minPayment: string;

  priceProfitType: string;
  priceProfit: string;
  priceOverComm: string;
  cancelPolicy: string;

  costCur: string;
  priceCur: string;
  curRateType: string;
  curRate: string;

  priceADT: string;
  priceCHD: string;

  costFixing: string;

  adultCost: Array<any>;
  adultPrice: Array<any>;
  roomADT: string;
  uniteCost: Array<any>;
  unitePrice: Array<any>;

  childAgeStart: Array<any>;
  childAgeFinish: Array<any>;
  childAgeFrom: Array<any>;
  childAgeTo: Array<any>;

  childCost: Array<any>;
  childPrice: Array<any>;
  childMinADT: Array<any>;


  chkedRoom: Array<any>;


  calcProfitType: Array<any>;
  calcProfit: Array<any>;
  calcDPP: Array<any>;
  calcSNG: Array<any>;
  calc3PER: Array<any>;
  calc4PER: Array<any>;
  calc1CHD1AGE: Array<any>;
  calc1CHD2AGE: Array<any>;
  calc2CHD1AGE: Array<any>;
  calc2CHD2AGE: Array<any>;

}

export default defineComponent({
  name: "new-over-commission-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  data() {
    return {
      startpicker: null,
      endpicker: null,
    }
  },

  methods: {},
  props: {
    hotelID: String,
    itemID: String,
  },

  watch: {
    itemID() {
      const payloadit = {
        "ID": this.itemID
      };
      store.dispatch(Actions.HOTEL_ROOM_PRICE_DETAILS, payloadit);
    },

  },
  setup(props) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newRoomModalRef = ref<null | HTMLElement>(null);



    function setDatePicker(sDate, fDate, acsDate, acfDate) {
      let myConfigS = {
        "locale": Turkish,
        dateFormat: "d-m-Y (D)",
        defaultDate: sDate,
      };

      let myConfigF = {
        "locale": Turkish,
        dateFormat: "d-m-Y (D)",
        defaultDate: fDate,
      };

      let myConfigAccS = {
        "locale": Turkish,
        dateFormat: "d-m-Y (D)",
        defaultDate: acsDate,
      };

      let myConfigAccF = {
        "locale": Turkish,
        dateFormat: "d-m-Y (D)",
        defaultDate: acfDate,
      };

      let tmpFinishDate = flatpickr("#updateCheckInDate", myConfigF);
      let tmpStartDate = flatpickr("#updateCheckOutDate", myConfigS);
      let tmpAccStartDate = flatpickr("#inp_HotelPriceUpdate_ValidStart", myConfigAccS);
      let tmpAccFinishDate = flatpickr("#inp_HotelPriceUpdate_ValidFinish", myConfigAccF);

      console.log("RoomPricesUpdate setDatePicker acsDate: "+acsDate+" acfDate: "+acfDate);

    }

    const payloadHD = {
      ID: props.hotelID,
    }
    //store.dispatch(Actions.HOTEL_DETAILS, payloadHD);
    const myDetails2 = computed(() => {
      return store.getters.hotelDetails;
    });

    const myDetails = computed(() => {
      let tmpArry = store.getters.hotelRoomPriceDetails;
      //console.log(tmpArry);
      setData(tmpArry);
      return tmpArry;
    });
    let childAgeGroupCount = ref([[]]);
    const setData = (tmpArry) => {
      //console.log("11");
      if (tmpArry.ID !== undefined) {
        console.log("tmpArry: "+JSON.stringify(tmpArry));
        newPriceData.value.priceMarket = tmpArry.Market;
        newPriceData.value.pricePlatform = tmpArry.Platform;
        newPriceData.value.priceType = tmpArry.RoomPriceType;
        newPriceData.value.minPayment = tmpArry.MinPayment;
        newPriceData.value.minAccType = tmpArry.MinDayType;
        newPriceData.value.minAccStatus = tmpArry.MinDayStatus;
        newPriceData.value.minAccDay = tmpArry.MinDay;
        newPriceData.value.priceProfitType = tmpArry.ProfitType;
        newPriceData.value.priceProfit = tmpArry.ProfitAmount;
        newPriceData.value.cancelPolicy = tmpArry.ReleasePolicy;
        newPriceData.value.priceCur = tmpArry.SaleCur;
        newPriceData.value.costCur = tmpArry.CostCur;
        newPriceData.value.priceRoom = tmpArry.RoomID;
        newPriceData.value.priceConcept = tmpArry.ConceptID;
        newPriceData.value.costFixing = tmpArry.CostFixing;

        newPriceData.value.dateReservationStart = dateConvert.method.dateToflatpicker(tmpArry.CheckInDate);
        newPriceData.value.dateReservationFinish = dateConvert.method.dateToflatpicker(tmpArry.CheckOutDate);
        newPriceData.value.dateValidStart = dateConvert.method.dateToflatpicker(tmpArry.BookingStartDate);
        newPriceData.value.dateValidFinish = dateConvert.method.dateToflatpicker(tmpArry.BookingFinisDate);
        newPriceData.value.chkCheckInDay = JSON.parse(tmpArry.EntryDay);
        newPriceData.value.chkValidDay = JSON.parse(tmpArry.ValidDay);

        if (tmpArry.RoomPriceType == "PAX") {
          let myPriceCalc = JSON.parse(tmpArry.PriceCalculate);
          newPriceData.value.calcProfitType[0] = myPriceCalc.Type;
          newPriceData.value.calcProfit[0] = myPriceCalc.Profit;
          newPriceData.value.calcDPP[0] = myPriceCalc.DPP;
          newPriceData.value.calcSNG[0] = myPriceCalc.SNG;
          newPriceData.value.calc3PER[0] = myPriceCalc.PAX3;
          newPriceData.value.calc4PER[0] = myPriceCalc.PAX4;
          newPriceData.value.calc1CHD1AGE[0] = myPriceCalc.CHD1AGE1;
          newPriceData.value.calc1CHD2AGE[0] = myPriceCalc.CHD1AGE2;
          newPriceData.value.calc2CHD1AGE[0] = myPriceCalc.CHD2AGE1;
          newPriceData.value.calc2CHD2AGE[0] = myPriceCalc.CHD2AGE2;

          let myPrice = JSON.parse(tmpArry.Price);
          newPriceData.value.adultPrice[0][0] = myPrice.PRICESNG;
          newPriceData.value.adultPrice[0][1] = myPrice.PRICEDPP;
          newPriceData.value.adultPrice[0][2] = myPrice.PRICEPAX3;
          newPriceData.value.adultPrice[0][3] = myPrice.PRICEPAX4;
          newPriceData.value.adultPrice[0][4] = myPrice.PRICEPAX5;
          newPriceData.value.adultPrice[0][5] = myPrice.PRICEPAX6;

          newPriceData.value.adultCost[0][0] = myPrice.COSTSNG;
          newPriceData.value.adultCost[0][1] = myPrice.COSTDPP;
          newPriceData.value.adultCost[0][2] = myPrice.COSTPAX3;
          newPriceData.value.adultCost[0][3] = myPrice.COSTPAX4;
          newPriceData.value.adultCost[0][4] = myPrice.COSTPAX5;
          newPriceData.value.adultCost[0][5] = myPrice.COSTPAX6;


          /************CHD1 Start**************/
          if (myPrice.CHD1.GROUP1.AGEFROM !== undefined) {
            childAgeGroupCount.value[0][0] = 1;
            newPriceData.value.childCost[0][0][0] = myPrice.CHD1.GROUP1.COST;
            newPriceData.value.childPrice[0][0][0] = myPrice.CHD1.GROUP1.PRICE;
            newPriceData.value.childAgeStart[0][0][0] = myPrice.CHD1.GROUP1.AGEFROM;
            newPriceData.value.childAgeFrom[0][0][0] = myPrice.CHD1.GROUP1.AGEFROM;
            newPriceData.value.childAgeFinish[0][0][0] = myPrice.CHD1.GROUP1.AGETO;
            newPriceData.value.childAgeTo[0][0][0] = myPrice.CHD1.GROUP1.AGETO;
            newPriceData.value.childMinADT[0][0][0] = myPrice.CHD1.GROUP1.MINADT;
          }

          if (myPrice.CHD1.GROUP2.AGEFROM !== undefined) {
            childAgeGroupCount.value[0][0] = 2;
            newPriceData.value.childCost[0][0][1] = myPrice.CHD1.GROUP2.COST;
            newPriceData.value.childPrice[0][0][1] = myPrice.CHD1.GROUP2.PRICE;
            newPriceData.value.childAgeStart[0][0][1] = myPrice.CHD1.GROUP2.AGEFROM;
            newPriceData.value.childAgeFrom[0][0][1] = myPrice.CHD1.GROUP2.AGEFROM;
            newPriceData.value.childAgeFinish[0][0][1] = myPrice.CHD1.GROUP2.AGETO;
            newPriceData.value.childAgeTo[0][0][1] = myPrice.CHD1.GROUP2.AGETO;
            newPriceData.value.childMinADT[0][0][1] = myPrice.CHD1.GROUP2.MINADT;
          }

          if (myPrice.CHD1.GROUP3.AGEFROM !== undefined) {
            childAgeGroupCount.value[0][0] = 3;
            newPriceData.value.childCost[0][0][2] = myPrice.CHD1.GROUP3.COST;
            newPriceData.value.childPrice[0][0][2] = myPrice.CHD1.GROUP3.PRICE;
            newPriceData.value.childAgeStart[0][0][2] = myPrice.CHD1.GROUP3.AGEFROM;
            newPriceData.value.childAgeFrom[0][0][2] = myPrice.CHD1.GROUP3.AGEFROM;
            newPriceData.value.childAgeFinish[0][0][2] = myPrice.CHD1.GROUP3.AGETO;
            newPriceData.value.childAgeTo[0][0][2] = myPrice.CHD1.GROUP3.AGETO;
            newPriceData.value.childMinADT[0][0][2] = myPrice.CHD1.GROUP3.MINADT;
          }

          if (myPrice.CHD1.GROUP4.AGEFROM !== undefined) {
            childAgeGroupCount.value[0][0] = 4;
            newPriceData.value.childCost[0][0][3] = myPrice.CHD1.GROUP4.COST;
            newPriceData.value.childPrice[0][0][3] = myPrice.CHD1.GROUP4.PRICE;
            newPriceData.value.childAgeStart[0][0][3] = myPrice.CHD1.GROUP4.AGEFROM;
            newPriceData.value.childAgeFrom[0][0][3] = myPrice.CHD1.GROUP4.AGEFROM;
            newPriceData.value.childAgeFinish[0][0][3] = myPrice.CHD1.GROUP4.AGETO;
            newPriceData.value.childAgeTo[0][0][3] = myPrice.CHD1.GROUP4.AGETO;
            newPriceData.value.childMinADT[0][0][3] = myPrice.CHD1.GROUP4.MINADT;
          }
          /************CHD1 End**************/

          /************CHD2 Start**************/
          if (myPrice.CHD2.GROUP1.AGEFROM !== undefined) {
            childAgeGroupCount.value[0][1] = 1;
            newPriceData.value.childCost[0][1][0] = myPrice.CHD2.GROUP1.COST;
            newPriceData.value.childPrice[0][1][0] = myPrice.CHD2.GROUP1.PRICE;
            newPriceData.value.childAgeStart[0][1][0] = myPrice.CHD2.GROUP1.AGEFROM;
            newPriceData.value.childAgeFrom[0][1][0] = myPrice.CHD2.GROUP1.AGEFROM;
            newPriceData.value.childAgeFinish[0][1][0] = myPrice.CHD2.GROUP1.AGETO;
            newPriceData.value.childAgeTo[0][1][0] = myPrice.CHD2.GROUP1.AGETO;
            newPriceData.value.childMinADT[0][1][0] = myPrice.CHD2.GROUP1.MINADT;
          }

          if (myPrice.CHD2.GROUP2.AGEFROM !== undefined) {
            childAgeGroupCount.value[0][1] = 2;
            newPriceData.value.childCost[0][1][1] = myPrice.CHD2.GROUP2.COST;
            newPriceData.value.childPrice[0][1][1] = myPrice.CHD2.GROUP2.PRICE;
            newPriceData.value.childAgeStart[0][1][1] = myPrice.CHD2.GROUP2.AGEFROM;
            newPriceData.value.childAgeFrom[0][1][1] = myPrice.CHD2.GROUP2.AGEFROM;
            newPriceData.value.childAgeFinish[0][1][1] = myPrice.CHD2.GROUP2.AGETO;
            newPriceData.value.childAgeTo[0][1][1] = myPrice.CHD2.GROUP2.AGETO;
            newPriceData.value.childMinADT[0][1][1] = myPrice.CHD2.GROUP2.MINADT;
          }

          if (myPrice.CHD2.GROUP3.AGEFROM !== undefined) {
            childAgeGroupCount.value[0][1] = 3;
            newPriceData.value.childCost[0][1][2] = myPrice.CHD2.GROUP3.COST;
            newPriceData.value.childPrice[0][1][2] = myPrice.CHD2.GROUP3.PRICE;
            newPriceData.value.childAgeStart[0][1][2] = myPrice.CHD2.GROUP3.AGEFROM;
            newPriceData.value.childAgeFrom[0][1][2] = myPrice.CHD2.GROUP3.AGEFROM;
            newPriceData.value.childAgeFinish[0][1][2] = myPrice.CHD2.GROUP3.AGETO;
            newPriceData.value.childAgeTo[0][1][2] = myPrice.CHD2.GROUP3.AGETO;
            newPriceData.value.childMinADT[0][1][2] = myPrice.CHD2.GROUP3.MINADT;
          }

          if (myPrice.CHD2.GROUP4.AGEFROM !== undefined) {
            childAgeGroupCount.value[0][1] = 4;
            newPriceData.value.childCost[0][1][3] = myPrice.CHD2.GROUP4.COST;
            newPriceData.value.childPrice[0][1][3] = myPrice.CHD2.GROUP4.PRICE;
            newPriceData.value.childAgeStart[0][1][3] = myPrice.CHD2.GROUP4.AGEFROM;
            newPriceData.value.childAgeFrom[0][1][3] = myPrice.CHD2.GROUP4.AGEFROM;
            newPriceData.value.childAgeFinish[0][1][3] = myPrice.CHD2.GROUP4.AGETO;
            newPriceData.value.childAgeTo[0][1][3] = myPrice.CHD2.GROUP4.AGETO;
            newPriceData.value.childMinADT[0][1][3] = myPrice.CHD2.GROUP4.MINADT;
          }
          /************CHD2 End**************/

          /************CHD3 Start**************/
          if (myPrice.CHD3.GROUP1.AGEFROM !== undefined) {
            childAgeGroupCount.value[0][2] = 1;
            newPriceData.value.childCost[0][2][0] = myPrice.CHD3.GROUP1.COST;
            newPriceData.value.childPrice[0][2][0] = myPrice.CHD3.GROUP1.PRICE;
            newPriceData.value.childAgeStart[0][2][0] = myPrice.CHD3.GROUP1.AGEFROM;
            newPriceData.value.childAgeFrom[0][2][0] = myPrice.CHD3.GROUP1.AGEFROM;
            newPriceData.value.childAgeFinish[0][2][0] = myPrice.CHD3.GROUP1.AGETO;
            newPriceData.value.childAgeTo[0][2][0] = myPrice.CHD3.GROUP1.AGETO;
            newPriceData.value.childMinADT[0][2][0] = myPrice.CHD3.GROUP1.MINADT;
          }

          if (myPrice.CHD3.GROUP2.AGEFROM !== undefined) {
            childAgeGroupCount.value[0][2] = 2;
            newPriceData.value.childCost[0][2][1] = myPrice.CHD3.GROUP2.COST;
            newPriceData.value.childPrice[0][2][1] = myPrice.CHD3.GROUP2.PRICE;
            newPriceData.value.childAgeStart[0][2][1] = myPrice.CHD3.GROUP2.AGEFROM;
            newPriceData.value.childAgeFrom[0][2][1] = myPrice.CHD3.GROUP2.AGEFROM;
            newPriceData.value.childAgeFinish[0][2][1] = myPrice.CHD3.GROUP2.AGETO;
            newPriceData.value.childAgeTo[0][2][1] = myPrice.CHD3.GROUP2.AGETO;
            newPriceData.value.childMinADT[0][2][1] = myPrice.CHD3.GROUP2.MINADT;
          }

          if (myPrice.CHD3.GROUP3.AGEFROM !== undefined) {
            childAgeGroupCount.value[0][2] = 3;
            newPriceData.value.childCost[0][2][2] = myPrice.CHD3.GROUP3.COST;
            newPriceData.value.childPrice[0][2][2] = myPrice.CHD3.GROUP3.PRICE;
            newPriceData.value.childAgeStart[0][2][2] = myPrice.CHD3.GROUP3.AGEFROM;
            newPriceData.value.childAgeFrom[0][2][2] = myPrice.CHD3.GROUP3.AGEFROM;
            newPriceData.value.childAgeFinish[0][2][2] = myPrice.CHD3.GROUP3.AGETO;
            newPriceData.value.childAgeTo[0][2][2] = myPrice.CHD3.GROUP3.AGETO;
            newPriceData.value.childMinADT[0][2][2] = myPrice.CHD3.GROUP3.MINADT;
          }

          if (myPrice.CHD3.GROUP4.AGEFROM !== undefined) {
            childAgeGroupCount.value[0][2] = 4;
            newPriceData.value.childCost[0][2][3] = myPrice.CHD3.GROUP4.COST;
            newPriceData.value.childPrice[0][2][3] = myPrice.CHD3.GROUP4.PRICE;
            newPriceData.value.childAgeStart[0][2][3] = myPrice.CHD3.GROUP4.AGEFROM;
            newPriceData.value.childAgeFrom[0][2][3] = myPrice.CHD3.GROUP4.AGEFROM;
            newPriceData.value.childAgeFinish[0][2][3] = myPrice.CHD3.GROUP4.AGETO;
            newPriceData.value.childAgeTo[0][2][3] = myPrice.CHD3.GROUP4.AGETO;
            newPriceData.value.childMinADT[0][2][3] = myPrice.CHD3.GROUP4.MINADT;
          }
          /************CHD3 End**************/

          /************CHD4 Start**************/
          if (myPrice.CHD4.GROUP1.AGEFROM !== undefined) {
            childAgeGroupCount.value[0][3] = 1;
            newPriceData.value.childCost[0][3][0] = myPrice.CHD4.GROUP1.COST;
            newPriceData.value.childPrice[0][3][0] = myPrice.CHD4.GROUP1.PRICE;
            newPriceData.value.childAgeStart[0][3][0] = myPrice.CHD4.GROUP1.AGEFROM;
            newPriceData.value.childAgeFrom[0][3][0] = myPrice.CHD4.GROUP1.AGEFROM;
            newPriceData.value.childAgeFinish[0][3][0] = myPrice.CHD4.GROUP1.AGETO;
            newPriceData.value.childAgeTo[0][3][0] = myPrice.CHD4.GROUP1.AGETO;
            newPriceData.value.childMinADT[0][3][0] = myPrice.CHD4.GROUP1.MINADT;
          }

          if (myPrice.CHD4.GROUP2.AGEFROM !== undefined) {
            childAgeGroupCount.value[0][3] = 2;
            newPriceData.value.childCost[0][3][1] = myPrice.CHD4.GROUP2.COST;
            newPriceData.value.childPrice[0][3][1] = myPrice.CHD4.GROUP2.PRICE;
            newPriceData.value.childAgeStart[0][3][1] = myPrice.CHD4.GROUP2.AGEFROM;
            newPriceData.value.childAgeFrom[0][3][1] = myPrice.CHD4.GROUP2.AGEFROM;
            newPriceData.value.childAgeFinish[0][3][1] = myPrice.CHD4.GROUP2.AGETO;
            newPriceData.value.childAgeTo[0][3][1] = myPrice.CHD4.GROUP2.AGETO;
            newPriceData.value.childMinADT[0][3][1] = myPrice.CHD4.GROUP2.MINADT;
          }

          if (myPrice.CHD4.GROUP3.AGEFROM !== undefined) {
            childAgeGroupCount.value[0][3] = 3;
            newPriceData.value.childCost[0][3][2] = myPrice.CHD4.GROUP3.COST;
            newPriceData.value.childPrice[0][3][2] = myPrice.CHD4.GROUP3.PRICE;
            newPriceData.value.childAgeStart[0][3][2] = myPrice.CHD4.GROUP3.AGEFROM;
            newPriceData.value.childAgeFrom[0][3][2] = myPrice.CHD4.GROUP3.AGEFROM;
            newPriceData.value.childAgeFinish[0][3][2] = myPrice.CHD4.GROUP3.AGETO;
            newPriceData.value.childAgeTo[0][3][2] = myPrice.CHD4.GROUP3.AGETO;
            newPriceData.value.childMinADT[0][3][2] = myPrice.CHD4.GROUP3.MINADT;
          }

          if (myPrice.CHD4.GROUP4.AGEFROM !== undefined) {
            childAgeGroupCount.value[0][3] = 4;
            newPriceData.value.childCost[0][3][3] = myPrice.CHD4.GROUP4.COST;
            newPriceData.value.childPrice[0][3][3] = myPrice.CHD4.GROUP4.PRICE;
            newPriceData.value.childAgeStart[0][3][3] = myPrice.CHD4.GROUP4.AGEFROM;
            newPriceData.value.childAgeFrom[0][3][3] = myPrice.CHD4.GROUP4.AGEFROM;
            newPriceData.value.childAgeFinish[0][3][3] = myPrice.CHD4.GROUP4.AGETO;
            newPriceData.value.childAgeTo[0][3][3] = myPrice.CHD4.GROUP4.AGETO;
            newPriceData.value.childMinADT[0][3][3] = myPrice.CHD4.GROUP4.MINADT;
          }
          /************CHD4 End**************/
        } else if (tmpArry.RoomPriceType == "UNITE") {
          let myPrice = JSON.parse(tmpArry.Price);
          newPriceData.value.unitePrice[0] = myPrice.PRICE;
          newPriceData.value.uniteCost[0] = myPrice.COST;
        }
        const payloadRoom = {
          "ID": tmpArry.RoomID
        };
        store.dispatch(Actions.HOTEL_ROOMS_LIST, payloadRoom);

        const payloadConcept = {
          "ID": tmpArry.ConceptID
        };
        store.dispatch(Actions.HOTEL_CONCEPTS_LIST, payloadConcept);


        if (tmpArry.CheckInDate) setDatePicker(dateConvert.method.convertToDate(tmpArry.CheckInDate), dateConvert.method.convertToDate(tmpArry.CheckOutDate), dateConvert.method.convertToDate(tmpArry.BookingStartDate), dateConvert.method.convertToDate(tmpArry.BookingFinisDate))
      }
    }

    onMounted(()=>{
      setTimeout(adultCounter, 2000);
      setTime();

    });

    function setTime() {
      console.log("pörklüü" + newPriceData.value.adultCost)
    }
    
    const myRoomsList = computed(() => {
      return store.getters.hotelRoomsList;
    });


    const myConceptList = computed(() => {
      return store.getters.hotelConceptsList;
    });
    const myPriceRoomList = computed(() => {
      if (myRoomsList.value.data !== undefined && myConceptList.value.data !== undefined) {
        let myData = [];
        let im = 0;
        for (let ir = 0; ir < myRoomsList.value.data.length; ir++) {
          for (let ic = 0; ic < myConceptList.value.data.length; ic++) {
            let myTemp = []
            myTemp['Key'] = "R" + myRoomsList.value.data[ir]['ID'] + "C" + myConceptList.value.data[ic]['ID'];
            myTemp['RoomID'] = myRoomsList.value.data[ir]['ID'];
            myTemp['RoomTitle'] = myRoomsList.value.data[ir]['Title'];
            myTemp['AdultMax'] = myRoomsList.value.data[ir]['AdultMax'];
            myTemp['ChildMax'] = myRoomsList.value.data[ir]['ChildMax'];
            myTemp['ConceptID'] = myConceptList.value.data[ic]['ID'];
            myTemp['ConceptTitle'] = myConceptList.value.data[ic]['Title'];
            myData.push(myTemp);

            adultCounter(im);
            setCapacity(im, myRoomsList.value.data[ir]['AdultMax'], myRoomsList.value.data[ir]['ChildMax']);
            im++;
          }
        }
        return myData;
      } else {
        return [];
      }
    });

    function adultCounter(key) {
      newPriceData.value.adultCost.push([]);
      newPriceData.value.adultPrice.push([]);
      newPriceData.value.childAgeStart.push([[[]], [[]], [[]], [[]]]);
      newPriceData.value.childAgeFinish.push([[[]], [[]], [[]], [[]]]);
      newPriceData.value.childCost.push([[[]], [[]], [[]], [[]]]);
      newPriceData.value.childPrice.push([[[]], [[]], [[]], [[]]]);
      newPriceData.value.childMinADT.push([[[]], [[]], [[]], [[]]]);

      newPriceData.value.childAgeTo.push([[[]], [[]], [[]], [[]]]);
      newPriceData.value.childAgeFrom.push([[[]], [[]], [[]], [[]]]);
      newPriceData.value.chkedRoom.push(true)
    }

    const newPriceData = ref<newPriceData>({
      priceRoom: "",
      priceConcept: "",
      pricePlatform: "",
      priceType: "",

      dateValidStart: "",
      dateValidFinish: "",
      dateReservationStart: "",
      dateReservationFinish: "",

      priceMarket: "",

      chkValidDay: [true, true, true, true, true, true, true],
      chkCheckInDay: [true, true, true, true, true, true, true],

      minAccType: "",
      minAccStatus: "",
      minAccDay: "",
      minPayment: "",

      priceProfitType: "",
      priceProfit: "",
      priceOverComm: "",
      cancelPolicy: "",

      costCur: "",
      priceCur: "",
      curRateType: "",
      curRate: "",

      priceADT: "",
      priceCHD: "",

      costFixing: "",

      adultCost: [],
      adultPrice: MasterConfig.roomPrice.adultData,
      roomADT: "",
      uniteCost: [],
      unitePrice: [],

      childAgeStart: [],
      childAgeFinish: [],
      childAgeFrom: [],
      childAgeTo: [],

      childCost: [],
      childPrice: [],
      childMinADT: [],

      chkedRoom: [],

      calcProfitType: [],
      calcProfit: [],
      calcDPP: [],
      calcSNG: [],
      calc3PER: [],
      calc4PER: [],
      calc1CHD1AGE: [],
      calc1CHD2AGE: [],
      calc2CHD1AGE: [],
      calc2CHD2AGE: [],
    });

    const validationSchema = Yup.object().shape({});
    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }
      //Disable button
      //submitButtonRef.value.disabled = true;
      // Activate indicator
      //submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      let payload = [];

      for (let ic = 0; ic < myPriceRoomList.value.length; ic++) {
        if (newPriceData.value.chkedRoom[ic]) {

          let calcPayload = {};
          let pricePayload = {};
          if(newPriceData.value.priceType == 'PAX') {
            calcPayload = {
              "Type": newPriceData.value.calcProfitType[ic],
              "Profit": newPriceData.value.calcProfit[ic],
              "DPP": newPriceData.value.calcDPP[ic],
              "SNG": newPriceData.value.calcSNG[ic],
              "PAX3": newPriceData.value.calc3PER[ic],
              "PAX4": newPriceData.value.calc4PER[ic],
              "CHD1AGE1": newPriceData.value.calc1CHD1AGE[ic],
              "CHD1AGE2": newPriceData.value.calc1CHD2AGE[ic],
              "CHD2AGE1": newPriceData.value.calc2CHD1AGE[ic],
              "CHD2AGE2": newPriceData.value.calc2CHD2AGE[ic],
            };
            pricePayload = {
              "PRICEDPP": newPriceData.value.adultPrice[ic][1],
              "PRICESNG": newPriceData.value.adultPrice[ic][0],
              "PRICEPAX3": newPriceData.value.adultPrice[ic][2],
              "PRICEPAX4": newPriceData.value.adultPrice[ic][3],
              "PRICEPAX5": newPriceData.value.adultPrice[ic][4],
              "PRICEPAX6": newPriceData.value.adultPrice[ic][5],
              "COSTDPP": newPriceData.value.adultCost[ic][1],
              "COSTSNG": newPriceData.value.adultCost[ic][0],
              "COSTPAX3": newPriceData.value.adultCost[ic][2],
              "COSTPAX4": newPriceData.value.adultCost[ic][3],
              "COSTPAX5": newPriceData.value.adultCost[ic][4],
              "COSTPAX6": newPriceData.value.adultCost[ic][5],
              "CHD1": {
                "GROUP1": {
                  "AGEFROM": newPriceData.value.childAgeStart[ic][0][0],
                  "AGETO": newPriceData.value.childAgeFinish[ic][0][0],
                  "PRICE": newPriceData.value.childPrice[ic][0][0],
                  "COST": newPriceData.value.childCost[ic][0][0],
                  "MINADT": newPriceData.value.childMinADT[ic][0][0],
                },
                "GROUP2": {
                  "AGEFROM": newPriceData.value.childAgeStart[ic][0][1],
                  "AGETO": newPriceData.value.childAgeFinish[ic][0][1],
                  "PRICE": newPriceData.value.childPrice[ic][0][1],
                  "COST": newPriceData.value.childCost[ic][0][1],
                  "MINADT": newPriceData.value.childMinADT[ic][0][1],
                },
                "GROUP3": {
                  "AGEFROM": newPriceData.value.childAgeStart[ic][0][2],
                  "AGETO": newPriceData.value.childAgeFinish[ic][0][2],
                  "PRICE": newPriceData.value.childPrice[ic][0][2],
                  "COST": newPriceData.value.childCost[ic][0][2],
                  "MINADT": newPriceData.value.childMinADT[ic][0][2],
                },
                "GROUP4": {
                  "AGEFROM": newPriceData.value.childAgeStart[ic][0][3],
                  "AGETO": newPriceData.value.childAgeFinish[ic][0][3],
                  "PRICE": newPriceData.value.childPrice[ic][0][3],
                  "COST": newPriceData.value.childCost[ic][0][3],
                  "MINADT": newPriceData.value.childMinADT[ic][0][3],
                },
              },
              "CHD2": {
                "GROUP1": {
                  "AGEFROM": newPriceData.value.childAgeStart[ic][1][0],
                  "AGETO": newPriceData.value.childAgeFinish[ic][1][0],
                  "PRICE": newPriceData.value.childPrice[ic][1][0],
                  "COST": newPriceData.value.childCost[ic][1][0],
                  "MINADT": newPriceData.value.childMinADT[ic][1][0],
                },
                "GROUP2": {
                  "AGEFROM": newPriceData.value.childAgeStart[ic][1][1],
                  "AGETO": newPriceData.value.childAgeFinish[ic][1][1],
                  "PRICE": newPriceData.value.childPrice[ic][1][1],
                  "COST": newPriceData.value.childCost[ic][1][1],
                  "MINADT": newPriceData.value.childMinADT[ic][1][1],
                },
                "GROUP3": {
                  "AGEFROM": newPriceData.value.childAgeStart[ic][1][2],
                  "AGETO": newPriceData.value.childAgeFinish[ic][1][2],
                  "PRICE": newPriceData.value.childPrice[ic][1][2],
                  "COST": newPriceData.value.childCost[ic][1][2],
                  "MINADT": newPriceData.value.childMinADT[ic][1][2],
                },
                "GROUP4": {
                  "AGEFROM": newPriceData.value.childAgeStart[ic][1][3],
                  "AGETO": newPriceData.value.childAgeFinish[ic][1][3],
                  "PRICE": newPriceData.value.childPrice[ic][1][3],
                  "COST": newPriceData.value.childCost[ic][1][3],
                  "MINADT": newPriceData.value.childMinADT[ic][1][3],
                },
              },
              "CHD3": {
                "GROUP1": {
                  "AGEFROM": newPriceData.value.childAgeStart[ic][2][0],
                  "AGETO": newPriceData.value.childAgeFinish[ic][2][0],
                  "PRICE": newPriceData.value.childPrice[ic][2][0],
                  "COST": newPriceData.value.childCost[ic][2][0],
                  "MINADT": newPriceData.value.childMinADT[ic][2][0],
                },
                "GROUP2": {
                  "AGEFROM": newPriceData.value.childAgeStart[ic][2][1],
                  "AGETO": newPriceData.value.childAgeFinish[ic][2][1],
                  "PRICE": newPriceData.value.childPrice[ic][2][1],
                  "COST": newPriceData.value.childCost[ic][2][1],
                  "MINADT": newPriceData.value.childMinADT[ic][2][1],
                },
                "GROUP3": {
                  "AGEFROM": newPriceData.value.childAgeStart[ic][2][2],
                  "AGETO": newPriceData.value.childAgeFinish[ic][2][2],
                  "PRICE": newPriceData.value.childPrice[ic][2][2],
                  "COST": newPriceData.value.childCost[ic][2][2],
                  "MINADT": newPriceData.value.childMinADT[ic][2][2],
                },
                "GROUP4": {
                  "AGEFROM": newPriceData.value.childAgeStart[ic][2][3],
                  "AGETO": newPriceData.value.childAgeFinish[ic][2][3],
                  "PRICE": newPriceData.value.childPrice[ic][2][3],
                  "COST": newPriceData.value.childCost[ic][2][3],
                  "MINADT": newPriceData.value.childMinADT[ic][2][3],
                },
              },
              "CHD4": {
                "GROUP1": {
                  "AGEFROM": newPriceData.value.childAgeStart[ic][3][0],
                  "AGETO": newPriceData.value.childAgeFinish[ic][3][0],
                  "PRICE": newPriceData.value.childPrice[ic][3][0],
                  "COST": newPriceData.value.childCost[ic][3][0],
                  "MINADT": newPriceData.value.childMinADT[ic][3][0],
                },
                "GROUP2": {
                  "AGEFROM": newPriceData.value.childAgeStart[ic][3][1],
                  "AGETO": newPriceData.value.childAgeFinish[ic][3][1],
                  "PRICE": newPriceData.value.childPrice[ic][3][1],
                  "COST": newPriceData.value.childCost[ic][3][1],
                  "MINADT": newPriceData.value.childMinADT[ic][3][1],
                },
                "GROUP3": {
                  "AGEFROM": newPriceData.value.childAgeStart[ic][3][2],
                  "AGETO": newPriceData.value.childAgeFinish[ic][3][2],
                  "PRICE": newPriceData.value.childPrice[ic][3][2],
                  "COST": newPriceData.value.childCost[ic][3][2],
                  "MINADT": newPriceData.value.childMinADT[ic][3][2],
                },
                "GROUP4": {
                  "AGEFROM": newPriceData.value.childAgeStart[ic][3][3],
                  "AGETO": newPriceData.value.childAgeFinish[ic][3][3],
                  "PRICE": newPriceData.value.childPrice[ic][3][3],
                  "COST": newPriceData.value.childCost[ic][3][3],
                  "MINADT": newPriceData.value.childMinADT[ic][3][3],
                },
              },
            };
          }else if (newPriceData.value.priceType == 'UNITE'){
            pricePayload = {
              "PRICE": newPriceData.value.unitePrice[ic],
              "COST": newPriceData.value.uniteCost[ic],

            };
          }

          let tmpPayload = {
            ID: props.itemID,
            HotelID: props.hotelID,
            HotelTitle: myPriceRoomList.value[ic].HotelTitle,
            RoomID: myPriceRoomList.value[ic].RoomID,
            ConceptID: myPriceRoomList.value[ic].ConceptID,
            RoomTitle: myPriceRoomList.value[ic].RoomTitle,
            ConceptTitle: myPriceRoomList.value[ic].ConceptTitle,
            Platform: newPriceData.value.pricePlatform,
            Market: newPriceData.value.priceMarket,
            SaleCur: newPriceData.value.priceCur,
            CostCur: newPriceData.value.costCur,
            CheckInDate: dateConvert.method.flatpickerToDateYM(newPriceData.value.dateReservationStart),
            CheckOutDate: dateConvert.method.flatpickerToDateYM(newPriceData.value.dateReservationFinish),
            BookingStartDate: dateConvert.method.flatpickerToDateYM(newPriceData.value.dateValidStart),
            BookingFinisDate: dateConvert.method.flatpickerToDateYM(newPriceData.value.dateValidFinish),
            MinDayType: newPriceData.value.minAccType,
            MinDay: newPriceData.value.minAccDay,
            MinDayStatus: newPriceData.value.minAccStatus,
            MinPayment: newPriceData.value.minPayment,
            EntryDay: JSON.stringify(newPriceData.value.chkCheckInDay),
            ValidDay: JSON.stringify(newPriceData.value.chkValidDay),
            ProfitType: newPriceData.value.priceProfitType,
            ProfitAmount: newPriceData.value.priceProfit,
            PriceCalculate: JSON.stringify(calcPayload),
            RoomPriceType: newPriceData.value.priceType,
            Price: JSON.stringify(pricePayload),
            PriceCostExchangeRate: newPriceData.value.curRate,
            PriceCostExchangeRateType: newPriceData.value.curRateType,
            ReleasePolicy: newPriceData.value.cancelPolicy,
            CostFixing: newPriceData.value.costFixing,
            RefundableStatus: "",

          };
          payload.push(tmpPayload);
        }
      }

      console.log(payload[0]);


      store.dispatch(Actions.HOTEL_ROOM_PRICE_EDIT, payload[0])
          .then(() => {
            Swal.fire({
              text: "Tebrikler. Başarılı bir şekilde ekleme işlemini tamamladınız.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Geri Dön!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              submitButtonRef.value.disabled = false;
              submitButtonRef.value.setAttribute("data-kt-indicator", "off");
              hideModal(newRoomModalRef.value);
              // Go to page after successfully login
              //router.push({name: "userlist"});
            });
          }).catch(() => {
        Swal.fire({
          text: store.getters.getHotelRoomPriceErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        submitButtonRef.value.disabled = false;
        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
      });

    };

    store.dispatch(Actions.MARKETS_LIST_CACHE, {});
    const myMarkets = computed(() => {
      return store.getters.MarketsListCache;
    });


    const setCapacity = (e, ADT, CHD) => {
      newPriceData.value.priceADT = ADT
      newPriceData.value.priceCHD = CHD
      let start = 1;
      //childAgeGroupCount.value[e] = Array.from({length: (Number(newPriceData.value.priceCHD) + 1) - start}, (v, k) => (start));
    }

    const myAgeGroupCount = (id, key) => {
      if (childAgeGroupCount.value[id] !== undefined) return childAgeGroupCount.value[id][key];
      //if(myChildCount[id]!==undefined)return myChildCount[id][key];
    };

    const myAgeGroupCountPlus = (id, key) => {
      childAgeGroupCount.value[id][key]++;
    };

    const myAgeGroupCountMinus = (id, key) => {
      childAgeGroupCount.value[id][key]--;
    };

    const arrPaxCom = computed(() => {
      let myADT = Number(newPriceData.value.priceADT);
      let myCHD = Number(newPriceData.value.priceCHD);
      let start = 1;
      let end = myADT + (myADT * myCHD);
      //const array1= Array.from({length: (myADT+1) - start}, (v, k) => (k + start) + "ADT");
      //const array2=Array.from({length: (myCHD+1) - start}, (v, k) => (k + start) + "CHD");
      if (myRoomsList.value !== undefined && myConceptList.value !== undefined) {

        const array1 = myRoomsList.value.data;
        const array2 = myConceptList.value.data;
        console.log(myRoomsList.value.data);
        console.log(myConceptList.value.data);
        return array1.concat(array1.flatMap(d => array2.map(v => d + "+" + v)));
      } else {
        return [];
      }
    });

    let myUnSelectedRoom = [];
    const capacityCom = (e) => {
      if (!e.target.checked && !myUnSelectedRoom.includes(e.target.value)) {
        myUnSelectedRoom.push(e.target.value);
      } else {
        let delKey = Object.keys(myUnSelectedRoom).find(key => myUnSelectedRoom[key] === e.target.value)
        myUnSelectedRoom.splice(Number(delKey), 1);
      }
    }

    const checkAll = (e) => {
      let myValue = (e.target.checked) ? true : false;
      if (myValue == false) myUnSelectedRoom = [];
      for (let ic = 0; ic < myPriceRoomList.value.length; ic++) {
        newPriceData.value.chkedRoom[ic] = myValue;
        if (myValue == true) myUnSelectedRoom.push(myPriceRoomList.value[ic]['Key']);
      }
    }

    const calcRoomPrice = (id) => {
      let PriceType = newPriceData.value.calcProfitType[id];
      let Profit = newPriceData.value.calcProfit[id];
      if (PriceType == 'NET') {
        let ProfitRate = (100 - Profit) / 100;
        if (myPriceRoomList.value[id]['AdultMax'] >= 2) newPriceData.value.adultCost[id][1] = Number(newPriceData.value.calcDPP[id]).toFixed(2);
        if (myPriceRoomList.value[id]['AdultMax'] >= 1) newPriceData.value.adultCost[id][0] = (Number(newPriceData.value.adultCost[id][1]) + (newPriceData.value.adultCost[id][1] * ((100 - newPriceData.value.calcSNG[id]) / 100))).toFixed(2);
        if (myPriceRoomList.value[id]['AdultMax'] >= 3) newPriceData.value.adultCost[id][2] = ((newPriceData.value.adultCost[id][1] * ((100 - newPriceData.value.calc3PER[id]) / 100))).toFixed(2);
        if (myPriceRoomList.value[id]['AdultMax'] >= 4) newPriceData.value.adultCost[id][3] = ((newPriceData.value.adultCost[id][1] * ((100 - newPriceData.value.calc4PER[id]) / 100))).toFixed(2);

        if (myPriceRoomList.value[id]['AdultMax'] >= 2) newPriceData.value.adultPrice[id][1] = (newPriceData.value.calcDPP[id] / ProfitRate).toFixed(2);
        if (myPriceRoomList.value[id]['AdultMax'] >= 1) newPriceData.value.adultPrice[id][0] = (Number(newPriceData.value.adultPrice[id][1]) + (newPriceData.value.adultPrice[id][1] * ((100 - newPriceData.value.calcSNG[id]) / 100))).toFixed(2);
        if (myPriceRoomList.value[id]['AdultMax'] >= 3) newPriceData.value.adultPrice[id][2] = ((newPriceData.value.adultPrice[id][1] * ((100 - newPriceData.value.calc3PER[id]) / 100))).toFixed(2);
        if (myPriceRoomList.value[id]['AdultMax'] >= 4) newPriceData.value.adultPrice[id][3] = ((newPriceData.value.adultPrice[id][1] * ((100 - newPriceData.value.calc4PER[id]) / 100))).toFixed(2);

        if (childAgeGroupCount.value[id][0] >= 1) newPriceData.value.childCost[id][0][0] = ((newPriceData.value.adultCost[id][1] * ((100 - newPriceData.value.calc1CHD1AGE[id]) / 100))).toFixed(2);
        if (childAgeGroupCount.value[id][0] >= 2) newPriceData.value.childCost[id][0][1] = ((newPriceData.value.adultCost[id][1] * ((100 - newPriceData.value.calc1CHD2AGE[id]) / 100))).toFixed(2);
        if (childAgeGroupCount.value[id][1] >= 1) newPriceData.value.childCost[id][1][0] = ((newPriceData.value.adultCost[id][1] * ((100 - newPriceData.value.calc2CHD1AGE[id]) / 100))).toFixed(2);
        if (childAgeGroupCount.value[id][1] >= 2) newPriceData.value.childCost[id][1][1] = ((newPriceData.value.adultCost[id][1] * ((100 - newPriceData.value.calc2CHD2AGE[id]) / 100))).toFixed(2);

        if (childAgeGroupCount.value[id][0] >= 1) newPriceData.value.childPrice[id][0][0] = ((newPriceData.value.adultPrice[id][1] * ((100 - newPriceData.value.calc1CHD1AGE[id]) / 100))).toFixed(2);
        if (childAgeGroupCount.value[id][0] >= 2) newPriceData.value.childPrice[id][0][1] = ((newPriceData.value.adultPrice[id][1] * ((100 - newPriceData.value.calc1CHD2AGE[id]) / 100))).toFixed(2);
        if (childAgeGroupCount.value[id][1] >= 1) newPriceData.value.childPrice[id][1][0] = ((newPriceData.value.adultPrice[id][1] * ((100 - newPriceData.value.calc2CHD1AGE[id]) / 100))).toFixed(2);
        if (childAgeGroupCount.value[id][1] >= 2) newPriceData.value.childPrice[id][1][1] = ((newPriceData.value.adultPrice[id][1] * ((100 - newPriceData.value.calc2CHD2AGE[id]) / 100))).toFixed(2);

      } else if (PriceType == 'COM') {
        let ProfitRate = (100 - Profit) / 100;
        if (myPriceRoomList.value[id]['AdultMax'] >= 2) newPriceData.value.adultPrice[id][1] = Number(newPriceData.value.calcDPP[id]).toFixed(2);
        if (myPriceRoomList.value[id]['AdultMax'] >= 1) newPriceData.value.adultPrice[id][0] = (Number(newPriceData.value.adultPrice[id][1]) + (newPriceData.value.adultPrice[id][1] * ((100 - newPriceData.value.calcSNG[id]) / 100))).toFixed(2);
        if (myPriceRoomList.value[id]['AdultMax'] >= 3) newPriceData.value.adultPrice[id][2] = ((newPriceData.value.adultPrice[id][1] * ((100 - newPriceData.value.calc3PER[id]) / 100))).toFixed(2);
        if (myPriceRoomList.value[id]['AdultMax'] >= 4) newPriceData.value.adultPrice[id][3] = ((newPriceData.value.adultPrice[id][1] * ((100 - newPriceData.value.calc4PER[id]) / 100))).toFixed(2);

        if (myPriceRoomList.value[id]['AdultMax'] >= 2) newPriceData.value.adultCost[id][1] = (newPriceData.value.calcDPP[id] * ProfitRate).toFixed(2);
        if (myPriceRoomList.value[id]['AdultMax'] >= 1) newPriceData.value.adultCost[id][0] = (Number(newPriceData.value.adultCost[id][1]) + (newPriceData.value.adultCost[id][1] * ((100 - newPriceData.value.calcSNG[id]) / 100))).toFixed(2);
        if (myPriceRoomList.value[id]['AdultMax'] >= 3) newPriceData.value.adultCost[id][2] = ((newPriceData.value.adultCost[id][1] * ((100 - newPriceData.value.calc3PER[id]) / 100))).toFixed(2);
        if (myPriceRoomList.value[id]['AdultMax'] >= 4) newPriceData.value.adultCost[id][3] = ((newPriceData.value.adultCost[id][1] * ((100 - newPriceData.value.calc4PER[id]) / 100))).toFixed(2);

        if (childAgeGroupCount.value[id][0] >= 1) newPriceData.value.childCost[id][0][0] = ((newPriceData.value.adultCost[id][1] * ((100 - newPriceData.value.calc1CHD1AGE[id]) / 100))).toFixed(2);
        if (childAgeGroupCount.value[id][0] >= 2) newPriceData.value.childCost[id][0][1] = ((newPriceData.value.adultCost[id][1] * ((100 - newPriceData.value.calc1CHD2AGE[id]) / 100))).toFixed(2);
        if (childAgeGroupCount.value[id][1] >= 1) newPriceData.value.childCost[id][1][0] = ((newPriceData.value.adultCost[id][1] * ((100 - newPriceData.value.calc2CHD1AGE[id]) / 100))).toFixed(2);
        if (childAgeGroupCount.value[id][1] >= 2) newPriceData.value.childCost[id][1][1] = ((newPriceData.value.adultCost[id][1] * ((100 - newPriceData.value.calc2CHD2AGE[id]) / 100))).toFixed(2);

        if (childAgeGroupCount.value[id][0] >= 1) newPriceData.value.childPrice[id][0][0] = ((newPriceData.value.adultPrice[id][1] * ((100 - newPriceData.value.calc1CHD1AGE[id]) / 100))).toFixed(2);
        if (childAgeGroupCount.value[id][0] >= 2) newPriceData.value.childPrice[id][0][1] = ((newPriceData.value.adultPrice[id][1] * ((100 - newPriceData.value.calc1CHD2AGE[id]) / 100))).toFixed(2);
        if (childAgeGroupCount.value[id][1] >= 1) newPriceData.value.childPrice[id][1][0] = ((newPriceData.value.adultPrice[id][1] * ((100 - newPriceData.value.calc2CHD1AGE[id]) / 100))).toFixed(2);
        if (childAgeGroupCount.value[id][1] >= 2) newPriceData.value.childPrice[id][1][1] = ((newPriceData.value.adultPrice[id][1] * ((100 - newPriceData.value.calc2CHD2AGE[id]) / 100))).toFixed(2);
      }
    };

    return {
      calcRoomPrice,
      myDetails,
      myDetails2,
      myRoomsList,
      myConceptList,
      myPriceRoomList,
      newPriceData,
      myMarkets,
      arrPaxCom,
      childAgeGroupCount,
      setCapacity,
      myAgeGroupCount,
      myAgeGroupCountPlus,
      myAgeGroupCountMinus,
      validationSchema,
      submit,
      capacityCom,
      checkAll,

      submitButtonRef,
      newRoomModalRef,
      roomProfitType,
      dateConvert,
      weekDaysList,
      roomMinAccType,
      curList,
      curExchangeType,
      roomPriceType,
      roomPricePlatform,
      roomCalcPriceType,
      MasterConfig,

    };
  },
});
